import React from 'react'
import { useTranslation } from 'react-i18next'

import { useReactiveVar } from '@apollo/client'
import Typography from '@mui/material/Typography'
import { format, intlFormat } from 'date-fns'
import { TimezoneBlock } from 'shared-components'
import { Analytics } from 'shared-components'

import { BookingForm } from '../../components/Forms'

import { reservationDateTime, store, timezoneAbbr } from '../../cache'
import i18next from '../../i18n'

import './style.css'

export const BookAppointment = () => {
  const { locationId, partnerId } = useReactiveVar(store)
  const datetime = useReactiveVar(reservationDateTime)
  const langCode = i18next.language
  const { t } = useTranslation()

  const dayOfWeek = intlFormat(
    datetime,
    { weekday: 'long' },
    { locale: langCode }
  ).toUpperCase()

  const formattedDateTime = intlFormat(
    datetime,
    {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    { locale: langCode }
  ).toUpperCase()

  React.useEffect(() => {
    Analytics.bookingStarted({
      dayOfWeek: format(datetime, 'EEEE').toUpperCase(),
      locationId,
      partnerId,
      time: format(datetime, 'HH:mm'),
    })
  }, [dayOfWeek, datetime, locationId, partnerId])

  return (
    <div className="booking-form-default">
      <div className="body">
        <div className="datetime-section">
          <Typography
            align="center"
            className="confirmationDatetime"
            gap={0}
            variant="h6"
          >
            {dayOfWeek}
            <br />
            {formattedDateTime}
          </Typography>
          <TimezoneBlock store={store} timezoneAbbr={timezoneAbbr} />
        </div>
        <div className="forms-section">
          <BookingForm />
        </div>
      </div>
    </div>
  )
}
