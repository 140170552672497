import React from 'react'

import { useReactiveVar } from '@apollo/client'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import IconButton from '@mui/material/IconButton'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Analytics } from 'shared-components'

import { store } from '../../cache'

export function NextMonth({ currentDate, setCalendarDate }) {
  const { locationId, partnerId } = useReactiveVar(store)
  const nextMonth = () => {
    const nextMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    )

    Analytics.nextMonthSelected({
      locationId,
      partnerId,
      month: format(nextMonthDate, 'MMMM'),
    })
    setCalendarDate(nextMonthDate)
  }

  return (
    <IconButton
      aria-label="next month"
      className="arrow-button"
      color="primary"
      data-testid="next-month-button"
      onClick={nextMonth}
      size="small"
    >
      <ChevronRightRoundedIcon />
    </IconButton>
  )
}

NextMonth.propTypes = {
  currentDate: PropTypes.instanceOf(Date),
  setCalendarDate: PropTypes.func,
}
