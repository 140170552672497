import { AddToCalendarButton } from 'add-to-calendar-button-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useReactiveVar } from '@apollo/client'
import { Typography } from '@mui/material'
import { format, intlFormat } from 'date-fns'
import { Analytics } from 'shared-components'
import { Divider } from 'shared-components'
import { Subheader } from 'shared-components'

import {
  locationData,
  patientData,
  reservationDateTime,
  store,
  timezoneAbbr,
} from '../../cache'
import i18next from '../../i18n'

import './style.css'

export const BookingConfirmation = () => {
  const location = useReactiveVar(locationData)
  const datetime = useReactiveVar(reservationDateTime)
  const patient = useReactiveVar(patientData)
  const timezone = useReactiveVar(timezoneAbbr)
  const { locationId, partnerId } = useReactiveVar(store)
  const atcDate = format(datetime, 'yyyy-MM-dd')
  const atcStartTime = format(datetime, 'HH:mm')
  const endTime = new Date(datetime)
  endTime.setHours(endTime.getHours() + 1)
  const atcEndTime = format(endTime, 'HH:mm')
  const { t } = useTranslation()
  const langCode = i18next.language

  const dayOfWeek = intlFormat(
    datetime,
    { weekday: 'long' },
    { locale: langCode }
  ).toUpperCase()

  const formattedDateTime = intlFormat(
    datetime,
    {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    { locale: langCode }
  ).toUpperCase()

  const formattedDateTimeWithTimezone = `${formattedDateTime} ${timezone}`

  React.useEffect(() => {
    Analytics.bookingCompleted({
      locationId,
      partnerId,
    })
  }, [locationId, partnerId])

  const formattedLocationAddress = location => {
    if (location) {
      return `${location.street} ${location.city}, ${location.state} ${location.zip}`
    }
    return ''
  }

  const locationTimezone = location => {
    return location ? location.timezone : 'currentBrowser'
  }

  const atcDesctription = location => {
    if (location) {
      return `${t('New patient exam with')} ${location.displayName}`
    } else {
      return t('New patient exam')
    }
  }

  const nextStepsFooter = location => {
    if (location) {
      return `${t(
        'You should receive an email with your appointment details shortly, followed by a contact from a member of'
      )} ${location.displayName} to
    confirm the appointment.`
    } else {
      return t(
        'You should receive an email with your appointment details shortly, followed by a contact from a member of the dental practice to confirm the appointment.'
      )
    }
  }

  const confirmedIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="80"
      viewBox="0 -960 960 960"
      width="80"
      fill="var(--intelibly-branding-primary-700)"
    >
      <path d="m438-327.384 147.078-147.077q8.922-8.923 21.499-9.115 12.577-.193 21.884 9.115 9.308 9.307 9.308 21.692 0 12.384-9.308 21.691L463.307-265.924Q452.461-255.078 438-255.078q-14.461 0-25.307-10.846l-81.154-81.154q-8.923-8.922-9.115-21.499-.193-12.577 9.115-21.884 9.307-9.308 21.692-9.308 12.384 0 21.691 9.308L438-327.384ZM212.309-100.001q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h55.385v-53.847q0-13.153 8.808-21.961 8.807-8.807 21.961-8.807 13.153 0 21.961 8.807 8.807 8.808 8.807 21.961v53.847h303.076v-54.616q0-12.769 8.615-21.384t21.384-8.615q12.769 0 21.384 8.615 8.616 8.615 8.616 21.384v54.616h55.385q30.308 0 51.308 21t21 51.308v535.382q0 30.308-21 51.308t-51.308 21H212.309Zm0-59.999h535.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-375.382H200v375.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846Z" />
    </svg>
  )

  return (
    <div className="confirmation">
      <div className="body-2">
        <div className="confirmation-details">
          <Subheader text={t('Appointment Booked')} icon={confirmedIcon} />
          <Typography
            align="center"
            className="confirmationDatetime"
            gap={0}
            variant="h6"
          >
            {dayOfWeek}
            <br />
            {formattedDateTimeWithTimezone}
          </Typography>
          <Typography
            align="center"
            className="confirmationEmail"
            variant="body1"
          >
            {t('Confirmation email sent to:')}
            <br />
            {patient && patient.email}
          </Typography>
          <AddToCalendarButton
            buttonStyle="round"
            description={atcDesctription(location)}
            endDate={atcDate}
            endTime={atcEndTime}
            hideCheckmark={true}
            iCalFileName="dental-appointment"
            identifier="add-to-calendar-button" // Helps track user interaction
            label={t('Add to calendar')}
            listStyle="modal"
            location={formattedLocationAddress(location)}
            name={t('Dental Appointment')}
            options={[
              'Apple',
              'Google',
              'Microsoft365',
              'MicrosoftTeams',
              'Outlook.com',
              'Yahoo',
              'iCal',
            ]}
            pastDateHandling="disable"
            size="7"
            startDate={atcDate}
            startTime={atcStartTime}
            timeZone={locationTimezone(location)}
          />
        </div>
        <Divider variant="secondary" />
        <div className="confirmation-details closing-details">
          <Typography
            align="center"
            className="next-steps"
            sx={{
              color: 'var(--intelibly-branding-neutral-800)',
            }}
            variant="subtitle2"
          >
            {nextStepsFooter(location)}
          </Typography>
          <Typography align="center" className="disclaimer" variant="subtitle2">
            {t(
              'Please note that the appointment time is tentative and may be subject to changes by the practice or provider.'
            )}
          </Typography>
        </div>
      </div>
    </div>
  )
}
