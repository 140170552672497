import React, { Suspense } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { createMemoryRouter, RouterProvider } from 'react-router-dom'

import { ApolloProvider, useReactiveVar } from '@apollo/client'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'shared-components'

import { alertType, client, message, store } from './cache'
import { routes } from './routes'

if (process.env.NODE_ENV === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}
const DolphinBooking = ({ locationId, partnerId, providerId }) => {
  React.useMemo(() => {
    store({ locationId, partnerId, providerId })
  }, [locationId, partnerId, providerId])

  const theme = React.useMemo(
    () =>
      createTheme({
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: '24px',
                height: '40px',
              },
            },
          },
        },
        palette: {
          primary: {
            main: 'rgba(140, 71, 153, 1)',
          },
        },
        typography: {
          fontFamily: ['Inter', 'Helvetica'].join(','),
          h5: {
            fontWeight: 600,
            color: 'var(--intelibly-branding-neutral-900)',
          },
          h6: {
            color: 'var(--intelibly-branding-neutral-700)',
            fontWeight: 600,
          },
          body1: {
            color: 'var(--intelibly-branding-neutral-900)',
          },
          body2: {
            color: 'var(--intelibly-branding-neutral-900)',
            fontWeight: 500,
          },
          button: {
            color: 'var(--intelibly-branding-primary-700)',
          },
          subtitle1: {
            color: 'var(--intelibly-branding-neutral-900)',
          },
          subtitle2: {
            color: 'var(--intelibly-branding-secondary-400)',
            lineHeight: 1.334,
          },
        },
      }),
    [createTheme]
  )

  const router = React.useMemo(() => createMemoryRouter(routes), [routes])
  const snackBarMessage = useReactiveVar(message)
  const snackBarVariant = useReactiveVar(alertType)

  const handleSnackbarClose = () => {
    message(null)
    alertType(null)
  }

  return (
    <ErrorBoundary fallback={<div>Oops something went wrong...</div>}>
      <Suspense
        fallback={
          <SkeletonTheme
            baseColor="var(--intelibly-branding-neutral-200)"
            highlightColor="var(--intelibly-branding-neutral-50)"
          />
        }
      >
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={Boolean(snackBarMessage)}
              onClose={handleSnackbarClose}
              autoHideDuration={10000}
            >
              <Alert
                severity={snackBarVariant || 'success'}
                onClose={handleSnackbarClose}
                sx={{ width: '100%' }}
              >
                {snackBarMessage}
              </Alert>
            </Snackbar>
            <RouterProvider
              router={router}
              fallbackElement={<div>Oops something went wrong...</div>}
            />
          </ThemeProvider>
        </ApolloProvider>
      </Suspense>
    </ErrorBoundary>
  )
}

DolphinBooking.propTypes = {
  locationId: PropTypes.string,
  practiceId: PropTypes.string,
  partnerId: PropTypes.string,
  providerId: PropTypes.string,
  intiateRequestAppointment: PropTypes.func,
}

export default DolphinBooking
