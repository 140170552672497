// Environment Helpers
export const isDev = process.env.NODE_ENV === 'development'

// Form Helpers
export const buildPatient = (isResponsibleParty, values) => {
  return {
    firstName: values.patient.firstName,
    lastName: values.patient.lastName,
    email:
      isResponsibleParty === 'self'
        ? values.patient.email
        : values.responsibleParty.email,
    phone:
      isResponsibleParty === 'self'
        ? values.patient.phone
        : values.responsibleParty.phone,
    dob: values.patient.dob,
    comment: values.patient.comment,
  }
}

export const buildResponsibleParty = (isResponsibleParty, values) => {
  return {
    firstName:
      isResponsibleParty === 'self'
        ? values.patient.firstName
        : values.responsibleParty.firstName,
    lastName:
      isResponsibleParty === 'self'
        ? values.patient.lastName
        : values.responsibleParty.lastName,
    email:
      isResponsibleParty === 'self'
        ? values.patient.email
        : values.responsibleParty.email,
    phone:
      isResponsibleParty === 'self'
        ? values.patient.phone
        : values.responsibleParty.phone,
    dob:
      isResponsibleParty === 'self'
        ? values.patient.dob
        : values.responsibleParty.dob,
  }
}
