import { gql } from '@apollo/client'

export const BOOK_DOLPHIN_APPOINTMENT = gql`
  mutation BookDolphinAppointment(
    $datetime: NaiveDateTime!
    $locationId: ID!
    $patient: DolphinPatientInput!
    $partnerId: ID
    $billingParty: DolphinBillingPartyInput!
  ) {
    bookDolphinAppointment(
      datetime: $datetime
      locationId: $locationId
      partnerId: $partnerId
      patient: $patient
      billingParty: $billingParty
    ) {
      ... on DolphinBookingIntent {
        id
        start
      }
      ... on UserInputValidationFailed {
        fieldErrors {
          errors {
            field
            message
          }
          field
          message
        }
      }
      ... on AppointmentLimitReached {
        message
      }
      ... on ReservationUnavailable {
        message
      }
    }
  }
`
