import { isDev } from './utils/helpers'

//some default pre init
var Countly = Countly || {}
Countly.q = Countly.q || []

//provide countly initialization parameters
Countly.app_key = 'c7e41cb524115b561c1ab37356f2d1d83b571645'
Countly.url = 'https://intelibly-4b4e8a93f3259.flex.countly.com'

Countly.q.push(['track_sessions'])
Countly.q.push(['track_pageview'])
Countly.q.push(['track_clicks'])
Countly.q.push(['track_scrolls'])
Countly.q.push(['track_errors'])
Countly.q.push(['track_links'])
Countly.q.push(['track_forms'])

//load countly script asynchronously when not in development mode
if (!isDev) {
  ;(function () {
    var cly = document.createElement('script')
    cly.type = 'text/javascript'
    cly.async = true
    //enter url of script here
    cly.src =
      'https://intelibly-4b4e8a93f3259.flex.countly.com/sdk/web/countly.min.js'
    cly.onload = function () {
      window.Countly.init()
    }
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(cly, s)
  })()
}
