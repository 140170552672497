import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useMutation, useReactiveVar } from '@apollo/client'
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import { Analytics } from 'shared-components'
import { Subheader } from 'shared-components'
import { boolean, date, object, string } from 'yup'

import {
  alertType,
  message,
  patientData,
  reservationDateTime,
  resetDolphinReservationCache,
  responsiblePartyData,
  store,
} from '../../cache'
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '../../constants'
import { BOOK_DOLPHIN_APPOINTMENT } from '../../queries/bookDolphinAppointment'
import { buildPatient, buildResponsibleParty } from '../../utils/helpers'

import './style.css'

export const BookingForm = () => {
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [isResponsibleParty, setResponsibleParty] = useState('self')
  const datetime = useReactiveVar(reservationDateTime)
  const patient = useReactiveVar(patientData)
  const responsibleParty = useReactiveVar(responsiblePartyData)
  const { locationId, partnerId } = useReactiveVar(store)
  const [submitAppointment, { loading }] = useMutation(BOOK_DOLPHIN_APPOINTMENT)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const termsOfUseLabel = { inputProps: { 'aria-label': 'Terms of Use' } }

  const initialValues = () => ({
    patient: {
      firstName: (patient && patient.firstName) || '',
      lastName: (patient && patient.lastName) || '',
      email: (patient && patient.email) || '',
      phone: (patient && patient.phone) || '',
      dob: (patient && patient.dob) || '',
      comment: (patient && patient.comment) || '',
      termsOfUse: false,
    },
    responsibleParty: {
      firstName: (responsibleParty && responsibleParty.firstName) || '',
      lastName: (responsibleParty && responsibleParty.lastName) || '',
      email: (responsibleParty && responsibleParty.email) || '',
      phone: (responsibleParty && responsibleParty.phone) || '',
      dob: (responsibleParty && responsibleParty.dob) || '',
    },
  })

  const validationSchema = isResponsibleParty =>
    object({
      patient: object({
        firstName: string().required(t('First name required')).max(50),
        lastName: string().required(t('Last name required')),
        email: string()
          .email()
          .when([], {
            is: () => isResponsibleParty === 'self',
            then: schema => schema.required(t('Email required')),
            otherwise: schema => schema.notRequired(),
          }),
        phone: string().when([], {
          is: () => isResponsibleParty === 'self',
          then: schema => schema.required(t('Mobile phone required')),
          otherwise: schema => schema.notRequired(),
        }),
        dob: date()
          .max(
            new Date(new Date().getFullYear() - 1, 11, 31),
            t('Birthdate must be in the past')
          )
          .required(t('Birthdate required')),
        comment: string().max(500),
        termsOfUse: boolean().oneOf([true]).required(),
      }),
      responsibleParty: object({
        firstName: string().when([], {
          is: () => isResponsibleParty === 'other',
          then: schema => schema.required(t('First name required')).max(50),
          otherwise: schema => schema.notRequired(),
        }),
        lastName: string().when([], {
          is: () => isResponsibleParty === 'other',
          then: schema => schema.required(t('Last name required')),
          otherwise: schema => schema.notRequired(),
        }),
        email: string()
          .email()
          .when([], {
            is: () => isResponsibleParty === 'other',
            then: schema => schema.required(t('Email required')),
            otherwise: schema => schema.notRequired(),
          }),
        phone: string().when([], {
          is: () => isResponsibleParty === 'other',
          then: schema => schema.required(t('Mobile phone required')),
          otherwise: schema => schema.notRequired(),
        }),
        dob: date().when([], {
          is: () => isResponsibleParty === 'other',
          then: schema =>
            schema
              .max(
                new Date(new Date().getFullYear() - 1, 11, 31),
                t('Birthdate must be prior to the previous year')
              )
              .required(t('Birthdate required')),
          otherwise: schema => schema.notRequired(),
        }),
      }),
    })

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(isResponsibleParty),
    onSubmit: async values => {
      const patientValues = buildPatient(isResponsibleParty, values)
      const responsiblePartyValues = buildResponsibleParty(
        isResponsibleParty,
        values
      )
      setIsBackdropOpen(true)
      patientData(patientValues)
      responsiblePartyData(responsiblePartyValues)
      const variables = {
        datetime: format(datetime, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        locationId,
        partnerId,
        patient: patientValues,
        billingParty: responsiblePartyValues,
      }
      try {
        const { data } = await submitAppointment({ variables })
        switch (data.bookDolphinAppointment.__typename) {
          case 'ReservationUnavailable':
            await resetDolphinReservationCache()
            alertType('warning')
            message(
              t(
                'Oops! That reservation time is no longer available. Please choose a new date or time while we keep your form saved.'
              )
            )
            navigate('/')
            break
          case 'AppointmentLimitReached':
            await resetDolphinReservationCache()
            alertType('error')
            message(
              t(
                "It looks like you've booked an appointment within the last 24 hours. We recommend contacting the practice directly to make any changes. If you believe this may be a mistake, please email support@intelibly.com."
              )
            )
            navigate('/')
            break
          default:
            if (
              data.bookDolphinAppointment.id &&
              data.bookDolphinAppointment.start
            ) {
              navigate('/booking-confirmation')
            }
        }
      } catch (error) {
        alertType('error')
        message(
          t(
            "Oops! Something didn't quite go as planned. Give it another try later and we'll get things sorted out."
          )
        )
        navigate('/')
      } finally {
        setIsBackdropOpen(false)
      }
    },
  })

  const handleBlur = event => {
    const patientValues = buildPatient(isResponsibleParty, formik.values)
    const responsiblePartyValues = buildResponsibleParty(
      isResponsibleParty,
      formik.values
    )
    Analytics.bookingFormFieldFilled({
      fieldName: event.target.name,
      locationId,
      partnerId,
    })
    formik.handleBlur(event)
    patientData(patientValues)
    responsiblePartyData(responsiblePartyValues)
  }

  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      <FormControl aria-label="responsible-party-section">
        <FormLabel id="responsible-party" sx={{ textAlign: 'center' }}>
          {t('Is this appointment for yourself or another person?')}
        </FormLabel>
        <RadioGroup
          aria-labelledby="responsible-party"
          name="responsible-party"
          value={isResponsibleParty}
          onChange={event => {
            setResponsibleParty(event.target.value)
          }}
          row
          sx={{ justifyContent: 'center' }}
        >
          <FormControlLabel
            value="self"
            control={<Radio />}
            label={t('Self')}
          />
          <FormControlLabel
            value="other"
            control={<Radio />}
            label={t('Other')}
          />
        </RadioGroup>
      </FormControl>
      {isResponsibleParty === 'other' && (
        <>
          <Subheader text={t('Responsible Party')} />
          <TextField
            aria-label="responsible-party-first-name"
            id="responsible-party-first-name"
            value={formik.values.responsibleParty.firstName}
            name="responsibleParty.firstName"
            onChange={formik.handleChange}
            onBlur={handleBlur}
            label={t('Responsible Party First Name')}
            variant="outlined"
            disabled={formik.isSubmitting}
            InputProps={{
              inputProps: { maxLength: 50 },
            }}
            error={
              formik.touched.responsibleParty?.firstName &&
              Boolean(formik.errors.responsibleParty?.firstName)
            }
            helperText={
              formik.touched.responsibleParty?.firstName &&
              formik.errors.responsibleParty?.firstName
            }
            required
            fullWidth
          />
          <TextField
            aria-label="responsible-party-last-name"
            id="responsible-party-last-name"
            value={formik.values.responsibleParty.lastName}
            name="responsibleParty.lastName"
            onChange={formik.handleChange}
            onBlur={handleBlur}
            error={
              formik.touched.responsibleParty?.lastName &&
              Boolean(formik.errors.responsibleParty?.lastName)
            }
            helperText={
              formik.touched.responsibleParty?.lastName &&
              formik.errors.responsibleParty?.lastName
            }
            disabled={formik.isSubmitting}
            label={t('Responsible Party Last Name')}
            variant="outlined"
            required
            fullWidth
          />
          <TextField
            aria-label="responsible-party-birthdate"
            id="responsible-party-birthdate"
            data-testid="responsible-party-birthdate"
            value={formik.values.responsibleParty.dob}
            name="responsibleParty.dob"
            type="date"
            onChange={formik.handleChange}
            onBlur={handleBlur}
            label={t('Responsible Party Birthdate')}
            variant="outlined"
            error={
              formik.touched.responsibleParty?.dob &&
              Boolean(formik.errors.responsibleParty?.dob)
            }
            helperText={
              formik.touched.responsibleParty?.dob &&
              formik.errors.responsibleParty?.dob
            }
            InputLabelProps={{ shrink: true }}
            disabled={formik.isSubmitting}
            required
            fullWidth
          />
          <TextField
            aria-label="responsible-party-email"
            id="email"
            value={formik.values.responsibleParty.email}
            name="responsibleParty.email"
            onChange={formik.handleChange}
            onBlur={handleBlur}
            error={
              formik.touched.responsibleParty?.email &&
              Boolean(formik.errors.responsibleParty?.email)
            }
            helperText={
              formik.touched.responsibleParty?.email &&
              formik.errors.responsibleParty?.email
            }
            disabled={formik.isSubmitting}
            label={t('Responsible Party Email')}
            variant="outlined"
            required
            fullWidth
          />
          <TextField
            aria-label="responsible-party-phone"
            id="phone"
            value={formik.values.responsibleParty.phone}
            name="responsibleParty.phone"
            onChange={formik.handleChange}
            onBlur={handleBlur}
            label={t('Responsible Party Mobile Phone')}
            variant="outlined"
            error={
              formik.touched.responsibleParty?.phone &&
              Boolean(formik.errors.responsibleParty?.phone)
            }
            helperText={
              formik.touched.responsibleParty?.phone &&
              formik.errors.responsibleParty?.phone
            }
            disabled={formik.isSubmitting}
            InputProps={{
              inputProps: { type: 'tel' },
            }}
            required
            fullWidth
          />
        </>
      )}
      <Subheader text={t('Patient Information')} />
      <TextField
        aria-label="patient-first-name"
        id="patient-first-name"
        value={formik.values.patient.firstName}
        name="patient.firstName"
        onChange={formik.handleChange}
        onBlur={handleBlur}
        label={t('Patient First Name')}
        variant="outlined"
        disabled={formik.isSubmitting}
        InputProps={{
          inputProps: { maxLength: 50 },
        }}
        error={
          formik.touched.patient?.firstName &&
          Boolean(formik.errors.patient?.firstName)
        }
        helperText={
          formik.touched.patient?.firstName && formik.errors.patient?.firstName
        }
        required
        fullWidth
      />
      <TextField
        aria-label="patient-last-name"
        id="patient-last-name"
        value={formik.values.patient.lastName}
        name="patient.lastName"
        onChange={formik.handleChange}
        onBlur={handleBlur}
        error={
          formik.touched.patient?.lastName &&
          Boolean(formik.errors.patient?.lastName)
        }
        helperText={
          formik.touched.patient?.lastName && formik.errors.patient?.lastName
        }
        disabled={formik.isSubmitting}
        label={t('Patient Last Name')}
        variant="outlined"
        required
        fullWidth
      />
      <TextField
        aria-label="patient-birthdate"
        id="date"
        data-testid="patient-birthdate"
        value={formik.values.patient.dob}
        name="patient.dob"
        type="date"
        onChange={formik.handleChange}
        onBlur={handleBlur}
        label={t('Patient Birthdate')}
        variant="outlined"
        error={
          formik.touched.patient?.dob && Boolean(formik.errors.patient?.dob)
        }
        helperText={formik.touched.patient?.dob && formik.errors.patient?.dob}
        InputLabelProps={{ shrink: true }}
        disabled={formik.isSubmitting}
        required
        fullWidth
      />
      {isResponsibleParty === 'self' && (
        <>
          <TextField
            aria-label="patient-email"
            id="email"
            value={formik.values.patient.email}
            name="patient.email"
            onChange={formik.handleChange}
            onBlur={handleBlur}
            error={
              formik.touched.patient?.email &&
              Boolean(formik.errors.patient?.email)
            }
            helperText={
              formik.touched.patient?.email && formik.errors.patient?.email
            }
            disabled={formik.isSubmitting}
            label={t('Patient Email')}
            variant="outlined"
            required
            fullWidth
          />
          <TextField
            aria-label="patient-phone"
            id="phone"
            value={formik.values.patient.phone}
            name="patient.phone"
            onChange={formik.handleChange}
            onBlur={handleBlur}
            label={t('Patient Mobile Phone')}
            variant="outlined"
            error={
              formik.touched.patient?.phone &&
              Boolean(formik.errors.patient?.phone)
            }
            helperText={
              formik.touched.patient?.phone && formik.errors.patient?.phone
            }
            disabled={formik.isSubmitting}
            InputProps={{
              inputProps: { type: 'tel' },
            }}
            required
            fullWidth
          />
        </>
      )}
      <TextField
        aria-label="comment"
        id="comment"
        value={formik.values.patient.comment}
        name="patient.comment"
        onChange={formik.handleChange}
        onBlur={handleBlur}
        label={t('Comment')}
        variant="outlined"
        minRows={2}
        maxRows={4}
        fullWidth
        multiline
        error={
          formik.touched.patient?.comment &&
          Boolean(formik.errors.patient?.comment)
        }
        disabled={formik.isSubmitting}
        helperText={
          formik.errors.patient?.comment
            ? formik.errors.patient?.comment
            : t(`Do not include any protected health information in the comment
        field. Additional details can be provided when contacted by the practice
        directly.`)
        }
        InputProps={{
          inputProps: { maxLength: 500 },
          sx: [
            {
              textarea: { marginBottom: theme => theme.spacing(2) },
            },
          ],
          endAdornment: (
            <InputAdornment
              id="comment-length"
              disableTypography
              disablePointerEvents
              position="start"
              sx={[{ alignSelf: 'flex-end', position: 'absolute' }]}
            >
              {formik.values.patient.comment.length} {t('characters')}
            </InputAdornment>
          ),
        }}
      />
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gap={1.5}
      >
        <Grid
          container
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          wrap="nowrap"
          gap={1}
        >
          <Checkbox
            {...termsOfUseLabel}
            name="patient.termsOfUse"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            color="primary"
            required
          />
          <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
            I have read and accept Intelibly's{' '}
            <Link
              data-testid="form-terms-and-conditions"
              variant="caption"
              href={TERMS_AND_CONDITIONS_URL}
              target="_blank"
              rel="noopener"
            >
              Terms and Conditions
            </Link>{' '}
            and I consent to Intelibly collecting data, including sensitive
            information as fully described in the{' '}
            <Link
              data-testid="form-privacy-policy"
              variant="caption"
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Grid>
        <Grid item>
          <Button
            aria-label="confirm"
            color="primary"
            disabled={formik.isSubmitting}
            disableElevation
            fullWidth
            type="submit"
            variant="contained"
          >
            {t('Confirm')}
          </Button>
        </Grid>
      </Grid>
      <Backdrop
        open={isBackdropOpen || loading}
        sx={{
          color: '#fff',
          opacity: 2,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  )
}
