import React from 'react'
import { useTranslation } from 'react-i18next'

import { useReactiveVar } from '@apollo/client'
import { Typography } from '@mui/material'
import { format, intlFormat } from 'date-fns'
import PropTypes from 'prop-types'
import { Analytics } from 'shared-components'

import { store } from '../../cache'
import i18next from '../../i18n'
import { Day } from '../Day'

import './style.css'

export const FirstAvailable = ({
  firstAvailableDay,
  onDaySelected,
  selectedDay,
}) => {
  const { locationId, partnerId } = useReactiveVar(store)
  const { t } = useTranslation()
  const langCode = i18next.language

  if (firstAvailableDay) {
    const formattedFirstAvailableDay = format(
      firstAvailableDay.date,
      'yyyy-MM-dd'
    )
    const formattedFirstAvailableMonth = intlFormat(
      firstAvailableDay.date,
      { month: 'short' },
      { locale: langCode }
    )

    const selectFirstAvailableDay = (day, availability) => {
      Analytics.firstAvailableSelected({ locationId, partnerId })
      onDaySelected(day, availability)
    }

    return (
      <div className="first-available">
        <Typography variant="subtitle1">
          {t(
            'Please select a date with available appointments. The next available appointment is:'
          )}
        </Typography>
        <div className="first-available-date">
          <Typography align="center" variant="body2" mt="-1px">
            {formattedFirstAvailableMonth}
          </Typography>
          <Day
            aria-label="first available date"
            availability={{
              date: formattedFirstAvailableDay,
              times: firstAvailableDay.times,
            }}
            onSelectDay={selectFirstAvailableDay}
            day={firstAvailableDay.date}
            selectedDay={selectedDay}
          />
        </div>
      </div>
    )
  }
}

FirstAvailable.propTypes = {
  className: PropTypes.string,
  firstAvailableDay: PropTypes.shape({
    date: PropTypes.instanceOf(Date),
    times: PropTypes.array,
  }),
  onDaySelected: PropTypes.func,
  selectedDay: PropTypes.instanceOf(Date),
  availableTimes: PropTypes.array,
}
