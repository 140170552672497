import { gql } from '@apollo/client'

export const DOLPHIN_RESERVATIONS = gql`
  query DolphinReservations(
    $locationId: ID!
    $startDateUtc: Date!
    $endDateUtc: Date!
  ) {
    dolphinReservations(
      endDateUtc: $endDateUtc
      startDateUtc: $startDateUtc
      locationId: $locationId
    ) {
      ... on DolphinReservations {
        reservations {
          date
          times
          timezoneAbbr
        }
      }
      __typename
      ... on VendorServiceUnavailable {
        message
      }
      ... on InvalidStartDate {
        message
      }
    }
  }
`
