import React from 'react'

import { useReactiveVar } from '@apollo/client'
import Button from '@mui/material/Button'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

import { timezoneAbbr } from '../../cache'

export const TimeSelector = ({ time, onTimeSelected }) => {
  const formatTime = time => {
    const date = new Date(`2000-01-01T${time}`)
    return format(date, 'h:mm a ')
  }
  const formattedTime = formatTime(time)
  const timezone = useReactiveVar(timezoneAbbr)

  return (
    <Button
      aria-label="time"
      onClick={() => onTimeSelected(time)}
      sx={{ width: '45%' }}
      variant="outlined"
    >
      {formattedTime}
      {timezone}
    </Button>
  )
}

TimeSelector.propTypes = {
  time: PropTypes.string,
  onTimeSelected: PropTypes.func,
}
