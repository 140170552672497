import { ApolloClient, InMemoryCache, makeVar } from '@apollo/client'

import { API_URL } from './constants'

const cache = new InMemoryCache()

export const client = new ApolloClient({
  uri: API_URL,
  cache: cache,
})

export const store = makeVar(null)
export const locationData = makeVar(null)
export const message = makeVar(null)
export const alertType = makeVar(null)
export const patientData = makeVar(null)
export const responsiblePartyData = makeVar(null)
export const reservationDateTime = makeVar(null)
export const reservationsAvailable = makeVar(true)
export const timezoneAbbr = makeVar(null)

export const resetDolphinReservationCache = () => {
  return client.refetchQueries({
    updateCache(cache) {
      cache.evict({ fieldName: 'dolphinReservations' })
    },
  })
}
