import React from 'react'

import { useReactiveVar } from '@apollo/client'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import IconButton from '@mui/material/IconButton'
import { format, isPast } from 'date-fns'
import PropTypes from 'prop-types'
import { Analytics } from 'shared-components'

import { store } from '../../cache'

export function PreviousMonth({
  currentDate,
  today,
  setCalendarDate,
  monthStart,
}) {
  const { locationId, partnerId } = useReactiveVar(store)
  const pastDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    currentDate.getDate()
  )

  const previousMonth = () => {
    if (!isPast(pastDate)) {
      Analytics.previousMonthSelected({
        locationId,
        partnerId,
        month: format(pastDate, 'MMMM'),
      })
      setCalendarDate(pastDate)
    } else {
      Analytics.previousMonthSelected({
        locationId,
        partnerId,
        month: format(today, 'MMMM'),
      })
      setCalendarDate(today)
    }
  }

  return (
    <IconButton
      aria-label="previous month"
      className="arrow-button"
      color="primary"
      data-testid="previous-month-button"
      disabled={isPast(monthStart)}
      onClick={previousMonth}
      size="small"
    >
      <ChevronLeftRoundedIcon />
    </IconButton>
  )
}

PreviousMonth.propTypes = {
  currentDate: PropTypes.instanceOf(Date),
  today: PropTypes.instanceOf(Date),
  setCalendarDate: PropTypes.func,
  monthStart: PropTypes.instanceOf(Date),
}
