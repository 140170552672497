import React from 'react'

import Button from '@mui/material/Button'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

import './style.css'

export const Day = ({ availability, day, onSelectDay, selectedDay }) => {
  const today = new Date()
  const formattedDay = format(day, 'd')
  const isSelected =
    selectedDay &&
    format(day, 'yyyy-MM-dd') === format(selectedDay, 'yyyy-MM-dd')
  const isToday = format(day, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd')
  const active = isSelected ? 'active' : 'default'
  const hasAvailability = availability.times.length > 0
  return (
    <div className="day-container">
      <Button
        aria-label="day"
        className={`day ${active}`}
        disabled={!hasAvailability}
        onClick={() => onSelectDay(day, availability)}
        sx={{ borderRadius: '12px', minWidth: '40px', maxWidth: '40px' }}
        title={formattedDay}
        type="button"
        variant={isToday ? 'outlined' : 'text'}
      >
        {formattedDay}
      </Button>
      {hasAvailability && <div className="badge" data-testid="badge"></div>}
    </div>
  )
}

Day.propTypes = {
  day: PropTypes.instanceOf(Date),
  availability: PropTypes.shape({
    date: PropTypes.string,
    times: PropTypes.array,
  }),
  onSelectDay: PropTypes.func,
  selectedDay: PropTypes.instanceOf(Date),
}
