import React, { StrictMode } from 'react'
import ReactDOMClient from 'react-dom/client'

import './countly'
import DolphinBooking from './DolphinBooking'
import './i18n'
import './sentry'

require('@iframe-resizer/child')

const app = document.getElementById('app')
const root = ReactDOMClient.createRoot(app)

const urlParams = new URLSearchParams(window.location.search)
const locationId = urlParams.get('location_id')
const providerId = urlParams.get('provider_id')

if (!window.Countly || !Array.isArray(window.Countly.q)) {
  window.Countly = { q: [] }
}

root.render(
  <StrictMode>
    <DolphinBooking locationId={locationId} providerId={providerId} />
  </StrictMode>
)
