import React from 'react'

import PropTypes from 'prop-types'

import { TimeSelector } from '../TimeSelector'

import './style.css'

export const TimePicker = ({ onTimeSelected, availableTimes }) => {
  return (
    <div className="time-picker">
      {availableTimes.map(time => {
        return (
          <TimeSelector
            key={time}
            time={time}
            onTimeSelected={onTimeSelected}
          />
        )
      })}
    </div>
  )
}

TimePicker.propTypes = {
  onTimeSelected: PropTypes.func,
  availableTimes: PropTypes.array,
}
