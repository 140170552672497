import React, { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom'

import { useReactiveVar } from '@apollo/client'
import { format, setHours, setMinutes } from 'date-fns'
import { Analytics } from 'shared-components'
import { DateSelectionSkeleton } from 'shared-components'
import { Divider } from 'shared-components'
import { RequestAppointmentButton } from 'shared-components'
import { ScrollHelper } from 'shared-components'
import { Subheader } from 'shared-components'

import { Calendar } from '../../components/Calendar'
import { TimePicker } from '../../components/TimePicker'

import { reservationDateTime, store } from '../../cache'

import './style.css'

export const DatetimeSelect = () => {
  const { locationId, partnerId } = useReactiveVar(store)
  const [selectedDay, setSelectedDay] = useState(null)
  const [availableTimes, setAvailableTimes] = useState([])
  const navigate = useNavigate()
  const { t } = useTranslation()

  const setInitialState = () => {
    setSelectedDay(null)
    setAvailableTimes([])
  }

  const onDaySelected = (day, availability = { times: [] }) => {
    if (day == null || day == undefined) {
      setInitialState()
    } else {
      setSelectedDay(day)
      setAvailableTimes(availability.times)
      Analytics.dateSelected({
        locationId,
        partnerId,
        dayOfWeek: format(day, 'EEEE'),
        month: format(day, 'MMMM'),
      })
    }
  }

  const onTimeSelected = time => {
    const [hours, minutes] = time.split(':')
    const datetime = setMinutes(setHours(selectedDay, hours), minutes)
    reservationDateTime(datetime)
    navigate('/book-appointment')
    Analytics.timeSelected({ locationId, partnerId, time })
  }

  return (
    <div className="datetime-select">
      <div className="body">
        <Suspense fallback={<DateSelectionSkeleton />}>
          <div className="date-selection">
            <Subheader text={t('Select a date')} />
            <Calendar
              onDaySelected={onDaySelected}
              selectedDay={selectedDay}
              navigate={navigate}
            />
          </div>
          {availableTimes.length > 0 ? (
            <div className="time-selection">
              <Subheader text={t('Select a time')} />
              <TimePicker
                onTimeSelected={onTimeSelected}
                availableTimes={availableTimes}
              />
            </div>
          ) : null}
        </Suspense>
        <Divider variant="secondary" />
        <RequestAppointmentButton />
        <ScrollHelper />
      </div>
    </div>
  )
}
