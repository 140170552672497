import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useReactiveVar, useSuspenseQuery } from '@apollo/client'
import TodayRoundedIcon from '@mui/icons-material/TodayRounded'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import * as Sentry from '@sentry/browser'
import {
  add,
  addDays,
  differenceInDays,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  endOfWeek,
  formatISO,
  intlFormat,
  isSameDay,
  parse,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import PropTypes from 'prop-types'
import { Analytics } from 'shared-components'
import { Divider } from 'shared-components'

import {
  alertType,
  message,
  reservationsAvailable,
  store,
  timezoneAbbr,
} from '../../cache'
import i18next from '../../i18n'
import { DOLPHIN_RESERVATIONS } from '../../queries/dolphinReservations'
import { newestToOldest } from '../../utils/newestToOldest'
import { toISOStringDate } from '../../utils/toISOStringDate'
import { Day } from '../Day'
import { FirstAvailable } from '../FirstAvailable'
import { NextMonth } from './NextMonth'
import { PreviousMonth } from './PreviousMonth'

import './style.css'

export const Calendar = ({ onDaySelected, navigate, selectedDay }) => {
  /**
   * Build calendar state based on today
   */
  const today = new Date()
  const [currentDate, setCurrentDate] = useState(today)
  const [firstAvailableDay, setFirstAvailableDay] = useState(null)
  const monthStart = startOfMonth(currentDate)
  const monthEnd = endOfMonth(currentDate)
  const weeks = eachWeekOfInterval({ start: monthStart, end: monthEnd })
  const { t } = useTranslation()
  const langCode = i18next.language
  const invalidDateMessage = t(
    'Oops! It looks like there is an issue with your request, please try using a different browser or try again later. If this issue persists please contact support@intelibly.com.'
  )

  const currentMonthYear = intlFormat(
    currentDate,
    {
      year: 'numeric',
      month: 'long',
    },
    { locale: langCode }
  )

  const localizedWeekdays = () => {
    const weekStart = startOfWeek(new Date(), { weekStartsOn: 0 })
    const weekdays = Array.from({ length: 7 }).map((_, i) => {
      return intlFormat(
        addDays(weekStart, i),
        { weekday: 'short' },
        { locale: langCode }
      )
    })
    return weekdays
  }
  const weekdays = localizedWeekdays()

  /**
   * Setup reservations query variables and results state
   */
  const [startDate, setStartDate] = useState(currentDate)
  const endDate = add(startDate, { days: 90 })
  const { locationId, partnerId } = useReactiveVar(store)
  const [dolphinReservations, setDolphinReservations] = useState([])
  const [queryCompleted, setQueryCompleted] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [vendorServiceUnavailable, setVendorServiceUnavailable] =
    useState(false)

  /**
   * Refetch locations when we reach end of 90 day period
   */
  useEffect(() => {
    if (differenceInDays(endDate, currentDate) < 0) {
      setStartDate(endDate)
    }
  }, [currentDate, endDate])

  const { data } = useSuspenseQuery(DOLPHIN_RESERVATIONS, {
    variables: {
      locationId,
      startDateUtc: toISOStringDate(startDate),
      endDateUtc: toISOStringDate(endDate),
    },
  })

  /**
   * Add newly fetched reservations to the state
   */
  const dolphinReservationsData = useMemo(
    () => data?.dolphinReservations,
    [data]
  )

  const handleDolphinReservations = useCallback(() => {
    if (dolphinReservationsData) {
      switch (data.dolphinReservations.__typename) {
        case 'DolphinReservations':
          setDolphinReservations(
            dolphinReservations.concat(dolphinReservationsData.reservations)
          )
          setQueryCompleted(true)
          break
        case 'VendorServiceUnavailable':
          setVendorServiceUnavailable(true)
          reservationsAvailable(false)
          Sentry.captureMessage(
            `Vendor Service Unavailable: ${data.dolphinReservations.message}`
          )
          navigate('/request-appointment')
          break
        case 'InvalidStartDate':
          alertType('warning')
          message(invalidDateMessage)
          Sentry.captureMessage(
            `Invalid Start Date: ${data.dolphinReservations.message}`
          )
          break
        default:
          Sentry.captureMessage(`Uncaught error in DolphinReservations`)
          undefined
          break
      }
    }
  }, [dolphinReservationsData, navigate])

  useEffect(() => {
    handleDolphinReservations()
  }, [handleDolphinReservations])

  /**
   * Select first available date of reservations on initial load
   */
  useEffect(() => {
    if (!initialLoad) {
      return undefined
    }
    if (dolphinReservations.length > 0) {
      const sortedReservations = dolphinReservations.toSorted(newestToOldest)
      const firstAvailableDay = parse(
        sortedReservations[0].date,
        'yyyy-MM-dd',
        new Date()
      )
      onDaySelected(
        firstAvailableDay,
        getAvailability(firstAvailableDay, dolphinReservations)
      )
      setFirstAvailableDay({
        date: firstAvailableDay,
        times: getAvailability(firstAvailableDay, dolphinReservations).times,
      })
      setInitialLoad(false)
    }
  }, [dolphinReservations, initialLoad])

  /**
   * Set timezone abbreviation state
   */
  useEffect(() => {
    if (dolphinReservations.length > 0) {
      timezoneAbbr(dolphinReservations[0].timezoneAbbr)
    }
  }, [dolphinReservations])

  /**
   * Returns the availability for a given day based on dolphin reservations.
   *
   * @param {Date} day - The day for which availability needs to be checked.
   * @param {Array} dolphinReservations - An array of dolphin reservations.
   * @return {Object} - The availability for the given day. If no availability is found, a default value is returned.
   */
  const getAvailability = (day, dolphinReservations) => {
    const formattedDay = formatISO(day, { representation: 'date' })
    const noAvailability = { date: formattedDay, times: [] } //** Default value */

    if (dolphinReservations && dolphinReservations.length > 0) {
      const foundAvailability = dolphinReservations.find(reservation => {
        return reservation.date == formattedDay
      })
      if (foundAvailability) {
        return foundAvailability
      } else {
        return noAvailability
      }
    }
    return noAvailability
  }

  // Forward to request appointment if no reservations
  useEffect(() => {
    if (!vendorServiceUnavailable && queryCompleted) {
      if (dolphinReservations.length === 0) {
        reservationsAvailable(false)
        navigate('/request-appointment')
      } else {
        reservationsAvailable(true)
      }
    }
  }, [dolphinReservations, queryCompleted, navigate, vendorServiceUnavailable])

  /** Update selected date when calendar date changes
   *  Boolean is false when today has reservations to prevent first available from rendering
   */
  const setCalendarDate = (date, resetSelectedDay = true) => {
    setCurrentDate(date)
    if (resetSelectedDay) {
      onDaySelected(null)
    }
  }

  /** Move to current month of the selectedDay when firstAvailable changed the selectedDay */
  React.useEffect(() => {
    if (selectedDay && !isSameDay(selectedDay, currentDate)) {
      setCurrentDate(selectedDay)
    }
  }, [selectedDay])

  /**
   * Goes to today's date in the calendar and performs necessary actions.
   *
   * @return {void}
   */
  const goToToday = () => {
    Analytics.todaySelected({
      locationId,
      partnerId,
    })
    const todayReservations = getAvailability(new Date(), dolphinReservations)
    if (todayReservations.times.length > 0) {
      onDaySelected(new Date(), todayReservations)
      setCalendarDate(today, false)
    } else {
      setCalendarDate(new Date())
    }
  }

  return (
    <div className="calendar">
      <div className="calendar-header">
        <Typography
          className="month-year"
          sx={{
            color: 'var(--intelibly-branding-neutral-900)',
            fontWeight: 500,
          }}
          variant="h6"
        >
          {currentMonthYear}
        </Typography>
        <div className="arrow-button-container">
          <PreviousMonth
            currentDate={currentDate}
            today={today}
            setCalendarDate={setCalendarDate}
            monthStart={monthStart}
          />
          <NextMonth
            currentDate={currentDate}
            setCalendarDate={setCalendarDate}
          />
        </div>
      </div>
      <Divider variant="secondary" />
      <Button
        aria-label="today"
        color="primary"
        fullWidth
        onClick={() => goToToday()}
        startIcon={<TodayRoundedIcon />}
        variant="text"
      >
        {t('Today')}
      </Button>
      <div className="date-picker">
        <ul className="weekdays">
          {weekdays.map(day => (
            <li key={day}>
              <Typography variant="body2" className="week-day" key={day}>
                {day}
              </Typography>
            </li>
          ))}
        </ul>
        <ol className="days-grid">
          {weeks.map(startOfWeek =>
            eachDayOfInterval({
              start: startOfWeek,
              end: endOfWeek(startOfWeek),
            }).map(day => (
              <li key={formatISO(day, { representation: 'date' })}>
                <Day
                  day={day}
                  availability={getAvailability(day, dolphinReservations)}
                  onSelectDay={onDaySelected}
                  selectedDay={selectedDay}
                />
              </li>
            ))
          )}
        </ol>
      </div>
      {selectedDay === null ||
      (selectedDay === undefined && firstAvailableDay) ? (
        <FirstAvailable
          firstAvailableDay={firstAvailableDay}
          onDaySelected={onDaySelected}
          selectedDay={selectedDay}
        />
      ) : null}
    </div>
  )
}

Calendar.propTypes = {
  divider: PropTypes.string,
  onDaySelected: PropTypes.func,
  selectedDay: PropTypes.instanceOf(Date),
  navigate: PropTypes.func,
}
